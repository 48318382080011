/* eslint-disable import/no-anonymous-default-export */
export default [
  // 20220822153202
  // https://dreamteam.sport5.co.il/API/GamesCycle/GetGameCycle/1

  {
    isFirstCycle: true,
    isLastCycle: false,
    cycleNum: 1,
    games: [
      {
        date: '22.08.2022',
        time: '19:00',
        score: '---',
        hostTeam: 'סקציה נס ציונה',
        hostTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostessTeam: 'הפועל קרית שמונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        ksarticle: '',
      },
      {
        date: '30.08.2022',
        time: '20:15',
        score: '---',
        hostTeam: 'בני סכנין',
        hostTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostessTeam: 'מכבי חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        ksarticle: '',
      },
      {
        date: '31.08.2022',
        time: '19:45',
        score: '---',
        hostTeam: 'הפועל באר שבע',
        hostTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostessTeam: 'מ.ס. אשדוד',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        ksarticle: '',
      },
      {
        date: '20.08.2022',
        time: 'הסתיים',
        score: '0:2',
        hostTeam: 'הפועל חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostessTeam: 'הפועל תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        ksarticle: 'https://www.sport5.co.il/articles.aspx?docID=412714',
      },
      {
        date: '20.08.2022',
        time: 'הסתיים',
        score: '1:1',
        hostTeam: 'הפועל ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostessTeam: 'הפועל חדרה',
        hostessTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        ksarticle: 'https://www.sport5.co.il/articles.aspx?docID=412715',
      },
      {
        date: '20.08.2022',
        time: 'הסתיים',
        score: '1:4',
        hostTeam: 'מכבי נתניה',
        hostTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostessTeam: 'בית``ר ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        ksarticle: 'https://www.sport5.co.il/articles.aspx?docID=412718',
      },
      {
        date: '21.08.2022',
        time: 'הסתיים',
        score: '0:5',
        hostTeam: 'מכבי תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostessTeam: 'מכבי בני ריינה',
        hostessTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        ksarticle: 'https://www.sport5.co.il/articles.aspx?docID=412813',
      },
    ],
  },
  // 20220822153151
  // https://dreamteam.sport5.co.il/API/GamesCycle/GetGameCycle/2
  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 2,
    games: [
      {
        date: '27.08.2022',
        time: '18:00',
        score: '---',
        hostTeam: 'מכבי בני ריינה',
        hostTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostessTeam: 'הפועל חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        ksarticle: '',
      },
      {
        date: '27.08.2022',
        time: '18:00',
        score: '---',
        hostTeam: 'הפועל קרית שמונה',
        hostTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostessTeam: 'הפועל ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        ksarticle: '',
      },
      {
        date: '27.08.2022',
        time: '20:15',
        score: '---',
        hostTeam: 'מ.ס. אשדוד',
        hostTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostessTeam: 'סקציה נס ציונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        ksarticle: '',
      },
      {
        date: '27.08.2022',
        time: '20:15',
        score: '---',
        hostTeam: 'הפועל תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostessTeam: 'בני סכנין',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        ksarticle: '',
      },
      {
        date: '28.08.2022',
        time: '20:15',
        score: '---',
        hostTeam: 'מכבי חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostessTeam: 'מכבי נתניה',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        ksarticle: '',
      },
      {
        date: '29.08.2022',
        time: '20:00',
        score: '---',
        hostTeam: 'הפועל חדרה',
        hostTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostessTeam: 'מכבי תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        ksarticle: '',
      },
      {
        date: '29.08.2022',
        time: '20:15',
        score: '---',
        hostTeam: 'בית``ר ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostessTeam: 'הפועל באר שבע',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        ksarticle: '',
      },
    ],
  },
  // 20220822152901
  // https://dreamteam.sport5.co.il/API/GamesCycle/GetGameCycle/3

  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 3,
    games: [
      {
        date: '03.09.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostessTeam: 'בני סכנין',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        ksarticle: '',
      },
      {
        date: '03.09.2022',
        time: '---',
        score: '---',
        hostTeam: 'מכבי נתניה',
        hostTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostessTeam: 'הפועל תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        ksarticle: '',
      },
      {
        date: '03.09.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל באר שבע',
        hostTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostessTeam: 'מכבי חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        ksarticle: '',
      },
      {
        date: '03.09.2022',
        time: '---',
        score: '---',
        hostTeam: 'סקציה נס ציונה',
        hostTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostessTeam: 'בית``ר ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        ksarticle: '',
      },
      {
        date: '03.09.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostessTeam: 'מ.ס. אשדוד',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        ksarticle: '',
      },
      {
        date: '03.09.2022',
        time: '---',
        score: '---',
        hostTeam: 'מכבי תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostessTeam: 'הפועל קרית שמונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        ksarticle: '',
      },
      {
        date: '03.09.2022',
        time: '---',
        score: '---',
        hostTeam: 'מכבי בני ריינה',
        hostTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostessTeam: 'הפועל חדרה',
        hostessTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        ksarticle: '',
      },
    ],
  },
  // 20220822151711
  // https://dreamteam.sport5.co.il/API/GamesCycle/GetGameCycle/4

  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 4,
    games: [
      {
        date: '10.09.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל חדרה',
        hostTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostessTeam: 'הפועל חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        ksarticle: '',
      },
      {
        date: '10.09.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל קרית שמונה',
        hostTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostessTeam: 'מכבי בני ריינה',
        hostessTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        ksarticle: '',
      },
      {
        date: '10.09.2022',
        time: '---',
        score: '---',
        hostTeam: 'מ.ס. אשדוד',
        hostTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostessTeam: 'מכבי תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        ksarticle: '',
      },
      {
        date: '10.09.2022',
        time: '---',
        score: '---',
        hostTeam: 'בית``ר ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostessTeam: 'הפועל ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        ksarticle: '',
      },
      {
        date: '10.09.2022',
        time: '---',
        score: '---',
        hostTeam: 'מכבי חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostessTeam: 'סקציה נס ציונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        ksarticle: '',
      },
      {
        date: '10.09.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostessTeam: 'הפועל באר שבע',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        ksarticle: '',
      },
      {
        date: '10.09.2022',
        time: '---',
        score: '---',
        hostTeam: 'בני סכנין',
        hostTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostessTeam: 'מכבי נתניה',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        ksarticle: '',
      },
    ],
  },
  // 20220822151726
  // https://dreamteam.sport5.co.il/API/GamesCycle/GetGameCycle/5

  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 5,
    games: [
      {
        date: '17.09.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostessTeam: 'מכבי נתניה',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        ksarticle: '',
      },
      {
        date: '17.09.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל באר שבע',
        hostTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostessTeam: 'בני סכנין',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        ksarticle: '',
      },
      {
        date: '17.09.2022',
        time: '---',
        score: '---',
        hostTeam: 'סקציה נס ציונה',
        hostTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostessTeam: 'הפועל תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        ksarticle: '',
      },
      {
        date: '17.09.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostessTeam: 'מכבי חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        ksarticle: '',
      },
      {
        date: '17.09.2022',
        time: '---',
        score: '---',
        hostTeam: 'מכבי תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostessTeam: 'בית``ר ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        ksarticle: '',
      },
      {
        date: '17.09.2022',
        time: '---',
        score: '---',
        hostTeam: 'מכבי בני ריינה',
        hostTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostessTeam: 'מ.ס. אשדוד',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        ksarticle: '',
      },
      {
        date: '17.09.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל חדרה',
        hostTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostessTeam: 'הפועל קרית שמונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        ksarticle: '',
      },
    ],
  },
  // 20220822151735
  // https://dreamteam.sport5.co.il/API/GamesCycle/GetGameCycle/6

  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 6,
    games: [
      {
        date: '01.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל קרית שמונה',
        hostTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostessTeam: 'הפועל חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        ksarticle: '',
      },
      {
        date: '01.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'מ.ס. אשדוד',
        hostTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostessTeam: 'הפועל חדרה',
        hostessTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        ksarticle: '',
      },
      {
        date: '01.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'בית``ר ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostessTeam: 'מכבי בני ריינה',
        hostessTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        ksarticle: '',
      },
      {
        date: '01.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'מכבי חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostessTeam: 'מכבי תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        ksarticle: '',
      },
      {
        date: '01.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostessTeam: 'הפועל ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        ksarticle: '',
      },
      {
        date: '01.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'בני סכנין',
        hostTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostessTeam: 'סקציה נס ציונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        ksarticle: '',
      },
      {
        date: '01.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'מכבי נתניה',
        hostTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostessTeam: 'הפועל באר שבע',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        ksarticle: '',
      },
    ],
  },
  // 20220822151752
  // https://dreamteam.sport5.co.il/API/GamesCycle/GetGameCycle/7

  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 7,
    games: [
      {
        date: '08.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostessTeam: 'הפועל באר שבע',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        ksarticle: '',
      },
      {
        date: '08.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'סקציה נס ציונה',
        hostTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostessTeam: 'מכבי נתניה',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        ksarticle: '',
      },
      {
        date: '08.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostessTeam: 'בני סכנין',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        ksarticle: '',
      },
      {
        date: '08.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'מכבי תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostessTeam: 'הפועל תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        ksarticle: '',
      },
      {
        date: '08.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'מכבי בני ריינה',
        hostTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostessTeam: 'מכבי חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        ksarticle: '',
      },
      {
        date: '08.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל חדרה',
        hostTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostessTeam: 'בית``ר ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        ksarticle: '',
      },
      {
        date: '08.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל קרית שמונה',
        hostTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostessTeam: 'מ.ס. אשדוד',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        ksarticle: '',
      },
    ],
  },
  // 20220822151802
  // https://dreamteam.sport5.co.il/API/GamesCycle/GetGameCycle/8

  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 8,
    games: [
      {
        date: '15.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'מ.ס. אשדוד',
        hostTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostessTeam: 'הפועל חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        ksarticle: '',
      },
      {
        date: '15.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'בית``ר ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostessTeam: 'הפועל קרית שמונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        ksarticle: '',
      },
      {
        date: '15.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'מכבי חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostessTeam: 'הפועל חדרה',
        hostessTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        ksarticle: '',
      },
      {
        date: '15.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostessTeam: 'מכבי בני ריינה',
        hostessTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        ksarticle: '',
      },
      {
        date: '15.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'בני סכנין',
        hostTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostessTeam: 'מכבי תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        ksarticle: '',
      },
      {
        date: '15.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'מכבי נתניה',
        hostTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostessTeam: 'הפועל ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        ksarticle: '',
      },
      {
        date: '15.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל באר שבע',
        hostTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostessTeam: 'סקציה נס ציונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        ksarticle: '',
      },
    ],
  },
  // 20220822151811
  // https://dreamteam.sport5.co.il/API/GamesCycle/GetGameCycle/9

  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 9,
    games: [
      {
        date: '22.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostessTeam: 'סקציה נס ציונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        ksarticle: '',
      },
      {
        date: '22.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostessTeam: 'הפועל באר שבע',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        ksarticle: '',
      },
      {
        date: '22.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'מכבי תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostessTeam: 'מכבי נתניה',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        ksarticle: '',
      },
      {
        date: '22.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'מכבי בני ריינה',
        hostTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostessTeam: 'בני סכנין',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        ksarticle: '',
      },
      {
        date: '22.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל חדרה',
        hostTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostessTeam: 'הפועל תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        ksarticle: '',
      },
      {
        date: '22.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל קרית שמונה',
        hostTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostessTeam: 'מכבי חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        ksarticle: '',
      },
      {
        date: '22.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'מ.ס. אשדוד',
        hostTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostessTeam: 'בית``ר ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        ksarticle: '',
      },
    ],
  },
  // 20220822151819
  // https://dreamteam.sport5.co.il/API/GamesCycle/GetGameCycle/10

  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 10,
    games: [
      {
        date: '29.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'בית``ר ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostessTeam: 'הפועל חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        ksarticle: '',
      },
      {
        date: '29.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'מכבי חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostessTeam: 'מ.ס. אשדוד',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        ksarticle: '',
      },
      {
        date: '29.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostessTeam: 'הפועל קרית שמונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        ksarticle: '',
      },
      {
        date: '29.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'בני סכנין',
        hostTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostessTeam: 'הפועל חדרה',
        hostessTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        ksarticle: '',
      },
      {
        date: '29.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'מכבי נתניה',
        hostTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostessTeam: 'מכבי בני ריינה',
        hostessTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        ksarticle: '',
      },
      {
        date: '29.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל באר שבע',
        hostTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostessTeam: 'מכבי תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        ksarticle: '',
      },
      {
        date: '29.10.2022',
        time: '---',
        score: '---',
        hostTeam: 'סקציה נס ציונה',
        hostTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostessTeam: 'הפועל ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        ksarticle: '',
      },
    ],
  },
  // 20220822151827
  // https://dreamteam.sport5.co.il/API/GamesCycle/GetGameCycle/11

  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 11,
    games: [
      {
        date: '05.11.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostessTeam: 'הפועל ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        ksarticle: '',
      },
      {
        date: '05.11.2022',
        time: '---',
        score: '---',
        hostTeam: 'מכבי תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostessTeam: 'סקציה נס ציונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        ksarticle: '',
      },
      {
        date: '05.11.2022',
        time: '---',
        score: '---',
        hostTeam: 'מכבי בני ריינה',
        hostTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostessTeam: 'הפועל באר שבע',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        ksarticle: '',
      },
      {
        date: '05.11.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל חדרה',
        hostTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostessTeam: 'מכבי נתניה',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        ksarticle: '',
      },
      {
        date: '05.11.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל קרית שמונה',
        hostTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostessTeam: 'בני סכנין',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        ksarticle: '',
      },
      {
        date: '05.11.2022',
        time: '---',
        score: '---',
        hostTeam: 'מ.ס. אשדוד',
        hostTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostessTeam: 'הפועל תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        ksarticle: '',
      },
      {
        date: '05.11.2022',
        time: '---',
        score: '---',
        hostTeam: 'בית``ר ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostessTeam: 'מכבי חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        ksarticle: '',
      },
    ],
  },
  // 20220822151840
  // https://dreamteam.sport5.co.il/API/GamesCycle/GetGameCycle/12

  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 12,
    games: [
      {
        date: '08.11.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostessTeam: 'מכבי חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        ksarticle: '',
      },
      {
        date: '08.11.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostessTeam: 'בית``ר ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        ksarticle: '',
      },
      {
        date: '08.11.2022',
        time: '---',
        score: '---',
        hostTeam: 'בני סכנין',
        hostTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostessTeam: 'מ.ס. אשדוד',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        ksarticle: '',
      },
      {
        date: '08.11.2022',
        time: '---',
        score: '---',
        hostTeam: 'מכבי נתניה',
        hostTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostessTeam: 'הפועל קרית שמונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        ksarticle: '',
      },
      {
        date: '08.11.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל באר שבע',
        hostTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostessTeam: 'הפועל חדרה',
        hostessTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        ksarticle: '',
      },
      {
        date: '08.11.2022',
        time: '---',
        score: '---',
        hostTeam: 'סקציה נס ציונה',
        hostTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostessTeam: 'מכבי בני ריינה',
        hostessTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        ksarticle: '',
      },
      {
        date: '08.11.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostessTeam: 'מכבי תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        ksarticle: '',
      },
    ],
  },
  // 20220822151859
  // https://dreamteam.sport5.co.il/API/GamesCycle/GetGameCycle/13

  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 13,
    games: [
      {
        date: '12.11.2022',
        time: '---',
        score: '---',
        hostTeam: 'מכבי תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostessTeam: 'הפועל חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        ksarticle: '',
      },
      {
        date: '12.11.2022',
        time: '---',
        score: '---',
        hostTeam: 'מכבי בני ריינה',
        hostTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostessTeam: 'הפועל ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        ksarticle: '',
      },
      {
        date: '12.11.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל חדרה',
        hostTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostessTeam: 'סקציה נס ציונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        ksarticle: '',
      },
      {
        date: '12.11.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל קרית שמונה',
        hostTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostessTeam: 'הפועל באר שבע',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        ksarticle: '',
      },
      {
        date: '12.11.2022',
        time: '---',
        score: '---',
        hostTeam: 'מ.ס. אשדוד',
        hostTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostessTeam: 'מכבי נתניה',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        ksarticle: '',
      },
      {
        date: '12.11.2022',
        time: '---',
        score: '---',
        hostTeam: 'בית``ר ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostessTeam: 'בני סכנין',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        ksarticle: '',
      },
      {
        date: '12.11.2022',
        time: '---',
        score: '---',
        hostTeam: 'מכבי חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostessTeam: 'הפועל תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        ksarticle: '',
      },
    ],
  },
  // 20220822151908
  // https://dreamteam.sport5.co.il/API/GamesCycle/GetGameCycle/14

  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 14,
    games: [
      {
        date: '17.12.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostessTeam: 'הפועל חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        ksarticle: '',
      },
      {
        date: '17.12.2022',
        time: '---',
        score: '---',
        hostTeam: 'מכבי חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostessTeam: 'בני סכנין',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        ksarticle: '',
      },
      {
        date: '17.12.2022',
        time: '---',
        score: '---',
        hostTeam: 'בית``ר ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostessTeam: 'מכבי נתניה',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        ksarticle: '',
      },
      {
        date: '17.12.2022',
        time: '---',
        score: '---',
        hostTeam: 'מ.ס. אשדוד',
        hostTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostessTeam: 'הפועל באר שבע',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        ksarticle: '',
      },
      {
        date: '17.12.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל קרית שמונה',
        hostTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostessTeam: 'סקציה נס ציונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        ksarticle: '',
      },
      {
        date: '17.12.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל חדרה',
        hostTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostessTeam: 'הפועל ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        ksarticle: '',
      },
      {
        date: '17.12.2022',
        time: '---',
        score: '---',
        hostTeam: 'מכבי בני ריינה',
        hostTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostessTeam: 'מכבי תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        ksarticle: '',
      },
    ],
  },
  // 20220822151916
  // https://dreamteam.sport5.co.il/API/GamesCycle/GetGameCycle/15

  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 15,
    games: [
      {
        date: '24.12.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostessTeam: 'מכבי בני ריינה',
        hostessTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        ksarticle: '',
      },
      {
        date: '24.12.2022',
        time: '---',
        score: '---',
        hostTeam: 'מכבי תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostessTeam: 'הפועל חדרה',
        hostessTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        ksarticle: '',
      },
      {
        date: '24.12.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostessTeam: 'הפועל קרית שמונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        ksarticle: '',
      },
      {
        date: '24.12.2022',
        time: '---',
        score: '---',
        hostTeam: 'סקציה נס ציונה',
        hostTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostessTeam: 'מ.ס. אשדוד',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        ksarticle: '',
      },
      {
        date: '24.12.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל באר שבע',
        hostTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostessTeam: 'בית``ר ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        ksarticle: '',
      },
      {
        date: '24.12.2022',
        time: '---',
        score: '---',
        hostTeam: 'מכבי נתניה',
        hostTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostessTeam: 'מכבי חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        ksarticle: '',
      },
      {
        date: '24.12.2022',
        time: '---',
        score: '---',
        hostTeam: 'בני סכנין',
        hostTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostessTeam: 'הפועל תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        ksarticle: '',
      },
    ],
  },
  // 20220822151951
  // https://dreamteam.sport5.co.il/API/GamesCycle/GetGameCycle/16

  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 16,
    games: [
      {
        date: '31.12.2022',
        time: '---',
        score: '---',
        hostTeam: 'בני סכנין',
        hostTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostessTeam: 'הפועל חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        ksarticle: '',
      },
      {
        date: '31.12.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostessTeam: 'מכבי נתניה',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        ksarticle: '',
      },
      {
        date: '31.12.2022',
        time: '---',
        score: '---',
        hostTeam: 'מכבי חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostessTeam: 'הפועל באר שבע',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        ksarticle: '',
      },
      {
        date: '31.12.2022',
        time: '---',
        score: '---',
        hostTeam: 'בית``ר ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostessTeam: 'סקציה נס ציונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        ksarticle: '',
      },
      {
        date: '31.12.2022',
        time: '---',
        score: '---',
        hostTeam: 'מ.ס. אשדוד',
        hostTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostessTeam: 'הפועל ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        ksarticle: '',
      },
      {
        date: '31.12.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל קרית שמונה',
        hostTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostessTeam: 'מכבי תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        ksarticle: '',
      },
      {
        date: '31.12.2022',
        time: '---',
        score: '---',
        hostTeam: 'הפועל חדרה',
        hostTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostessTeam: 'מכבי בני ריינה',
        hostessTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        ksarticle: '',
      },
    ],
  },
  // 20220822151959
  // https://dreamteam.sport5.co.il/API/GamesCycle/GetGameCycle/17

  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 17,
    games: [
      {
        date: '07.01.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostessTeam: 'הפועל חדרה',
        hostessTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        ksarticle: '',
      },
      {
        date: '07.01.2023',
        time: '---',
        score: '---',
        hostTeam: 'מכבי בני ריינה',
        hostTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostessTeam: 'הפועל קרית שמונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        ksarticle: '',
      },
      {
        date: '07.01.2023',
        time: '---',
        score: '---',
        hostTeam: 'מכבי תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostessTeam: 'מ.ס. אשדוד',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        ksarticle: '',
      },
      {
        date: '07.01.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostessTeam: 'בית``ר ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        ksarticle: '',
      },
      {
        date: '07.01.2023',
        time: '---',
        score: '---',
        hostTeam: 'סקציה נס ציונה',
        hostTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostessTeam: 'מכבי חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        ksarticle: '',
      },
      {
        date: '07.01.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל באר שבע',
        hostTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostessTeam: 'הפועל תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        ksarticle: '',
      },
      {
        date: '07.01.2023',
        time: '---',
        score: '---',
        hostTeam: 'מכבי נתניה',
        hostTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostessTeam: 'בני סכנין',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        ksarticle: '',
      },
    ],
  },
  // 20220822152006
  // https://dreamteam.sport5.co.il/API/GamesCycle/GetGameCycle/18

  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 18,
    games: [
      {
        date: '14.01.2023',
        time: '---',
        score: '---',
        hostTeam: 'מכבי נתניה',
        hostTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostessTeam: 'הפועל חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        ksarticle: '',
      },
      {
        date: '14.01.2023',
        time: '---',
        score: '---',
        hostTeam: 'בני סכנין',
        hostTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostessTeam: 'הפועל באר שבע',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        ksarticle: '',
      },
      {
        date: '14.01.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostessTeam: 'סקציה נס ציונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        ksarticle: '',
      },
      {
        date: '14.01.2023',
        time: '---',
        score: '---',
        hostTeam: 'מכבי חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostessTeam: 'הפועל ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        ksarticle: '',
      },
      {
        date: '14.01.2023',
        time: '---',
        score: '---',
        hostTeam: 'בית``ר ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostessTeam: 'מכבי תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        ksarticle: '',
      },
      {
        date: '14.01.2023',
        time: '---',
        score: '---',
        hostTeam: 'מ.ס. אשדוד',
        hostTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostessTeam: 'מכבי בני ריינה',
        hostessTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        ksarticle: '',
      },
      {
        date: '14.01.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל קרית שמונה',
        hostTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostessTeam: 'הפועל חדרה',
        hostessTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        ksarticle: '',
      },
    ],
  },
  // 20220822152015
  // https://dreamteam.sport5.co.il/API/GamesCycle/GetGameCycle/19

  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 19,
    games: [
      {
        date: '21.01.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostessTeam: 'הפועל קרית שמונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        ksarticle: '',
      },
      {
        date: '21.01.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל חדרה',
        hostTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostessTeam: 'מ.ס. אשדוד',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        ksarticle: '',
      },
      {
        date: '21.01.2023',
        time: '---',
        score: '---',
        hostTeam: 'מכבי בני ריינה',
        hostTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostessTeam: 'בית``ר ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        ksarticle: '',
      },
      {
        date: '21.01.2023',
        time: '---',
        score: '---',
        hostTeam: 'מכבי תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostessTeam: 'מכבי חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        ksarticle: '',
      },
      {
        date: '21.01.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostessTeam: 'הפועל תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        ksarticle: '',
      },
      {
        date: '21.01.2023',
        time: '---',
        score: '---',
        hostTeam: 'סקציה נס ציונה',
        hostTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostessTeam: 'בני סכנין',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        ksarticle: '',
      },
      {
        date: '21.01.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל באר שבע',
        hostTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostessTeam: 'מכבי נתניה',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        ksarticle: '',
      },
    ],
  },
  // 20220822152024
  // https://dreamteam.sport5.co.il/API/GamesCycle/GetGameCycle/20

  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 20,
    games: [
      {
        date: '28.01.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל באר שבע',
        hostTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostessTeam: 'הפועל חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        ksarticle: '',
      },
      {
        date: '28.01.2023',
        time: '---',
        score: '---',
        hostTeam: 'מכבי נתניה',
        hostTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostessTeam: 'סקציה נס ציונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        ksarticle: '',
      },
      {
        date: '28.01.2023',
        time: '---',
        score: '---',
        hostTeam: 'בני סכנין',
        hostTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostessTeam: 'הפועל ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        ksarticle: '',
      },
      {
        date: '28.01.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostessTeam: 'מכבי תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        ksarticle: '',
      },
      {
        date: '28.01.2023',
        time: '---',
        score: '---',
        hostTeam: 'מכבי חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostessTeam: 'מכבי בני ריינה',
        hostessTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        ksarticle: '',
      },
      {
        date: '28.01.2023',
        time: '---',
        score: '---',
        hostTeam: 'בית``ר ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostessTeam: 'הפועל חדרה',
        hostessTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        ksarticle: '',
      },
      {
        date: '28.01.2023',
        time: '---',
        score: '---',
        hostTeam: 'מ.ס. אשדוד',
        hostTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostessTeam: 'הפועל קרית שמונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        ksarticle: '',
      },
    ],
  },
  // 20220822152032
  // https://dreamteam.sport5.co.il/API/GamesCycle/GetGameCycle/21

  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 21,
    games: [
      {
        date: '04.02.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostessTeam: 'מ.ס. אשדוד',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        ksarticle: '',
      },
      {
        date: '04.02.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל קרית שמונה',
        hostTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostessTeam: 'בית``ר ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        ksarticle: '',
      },
      {
        date: '04.02.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל חדרה',
        hostTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostessTeam: 'מכבי חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        ksarticle: '',
      },
      {
        date: '04.02.2023',
        time: '---',
        score: '---',
        hostTeam: 'מכבי בני ריינה',
        hostTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostessTeam: 'הפועל תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        ksarticle: '',
      },
      {
        date: '04.02.2023',
        time: '---',
        score: '---',
        hostTeam: 'מכבי תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostessTeam: 'בני סכנין',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        ksarticle: '',
      },
      {
        date: '04.02.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostessTeam: 'מכבי נתניה',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        ksarticle: '',
      },
      {
        date: '04.02.2023',
        time: '---',
        score: '---',
        hostTeam: 'סקציה נס ציונה',
        hostTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostessTeam: 'הפועל באר שבע',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        ksarticle: '',
      },
    ],
  },
  // 20220822152041
  // https://dreamteam.sport5.co.il/API/GamesCycle/GetGameCycle/22

  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 22,
    games: [
      {
        date: '11.02.2023',
        time: '---',
        score: '---',
        hostTeam: 'סקציה נס ציונה',
        hostTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostessTeam: 'הפועל חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        ksarticle: '',
      },
      {
        date: '11.02.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל באר שבע',
        hostTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostessTeam: 'הפועל ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        ksarticle: '',
      },
      {
        date: '11.02.2023',
        time: '---',
        score: '---',
        hostTeam: 'מכבי נתניה',
        hostTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostessTeam: 'מכבי תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        ksarticle: '',
      },
      {
        date: '11.02.2023',
        time: '---',
        score: '---',
        hostTeam: 'בני סכנין',
        hostTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostessTeam: 'מכבי בני ריינה',
        hostessTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        ksarticle: '',
      },
      {
        date: '11.02.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostessTeam: 'הפועל חדרה',
        hostessTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        ksarticle: '',
      },
      {
        date: '11.02.2023',
        time: '---',
        score: '---',
        hostTeam: 'מכבי חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostessTeam: 'הפועל קרית שמונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        ksarticle: '',
      },
      {
        date: '11.02.2023',
        time: '---',
        score: '---',
        hostTeam: 'בית``ר ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostessTeam: 'מ.ס. אשדוד',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        ksarticle: '',
      },
    ],
  },
  // 20220822152049
  // https://dreamteam.sport5.co.il/API/GamesCycle/GetGameCycle/23

  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 23,
    games: [
      {
        date: '18.02.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostessTeam: 'בית``ר ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        ksarticle: '',
      },
      {
        date: '18.02.2023',
        time: '---',
        score: '---',
        hostTeam: 'מ.ס. אשדוד',
        hostTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostessTeam: 'מכבי חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        ksarticle: '',
      },
      {
        date: '18.02.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל קרית שמונה',
        hostTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostessTeam: 'הפועל תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        ksarticle: '',
      },
      {
        date: '18.02.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל חדרה',
        hostTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostessTeam: 'בני סכנין',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        ksarticle: '',
      },
      {
        date: '18.02.2023',
        time: '---',
        score: '---',
        hostTeam: 'מכבי בני ריינה',
        hostTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostessTeam: 'מכבי נתניה',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        ksarticle: '',
      },
      {
        date: '18.02.2023',
        time: '---',
        score: '---',
        hostTeam: 'מכבי תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostessTeam: 'הפועל באר שבע',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        ksarticle: '',
      },
      {
        date: '18.02.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostessTeam: 'סקציה נס ציונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        ksarticle: '',
      },
    ],
  },
  // 20220822152056
  // https://dreamteam.sport5.co.il/API/GamesCycle/GetGameCycle/24

  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 24,
    games: [
      {
        date: '25.02.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostessTeam: 'הפועל חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        ksarticle: '',
      },
      {
        date: '25.02.2023',
        time: '---',
        score: '---',
        hostTeam: 'סקציה נס ציונה',
        hostTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostessTeam: 'מכבי תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        ksarticle: '',
      },
      {
        date: '25.02.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל באר שבע',
        hostTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostessTeam: 'מכבי בני ריינה',
        hostessTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        ksarticle: '',
      },
      {
        date: '25.02.2023',
        time: '---',
        score: '---',
        hostTeam: 'מכבי נתניה',
        hostTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostessTeam: 'הפועל חדרה',
        hostessTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        ksarticle: '',
      },
      {
        date: '25.02.2023',
        time: '---',
        score: '---',
        hostTeam: 'בני סכנין',
        hostTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostessTeam: 'הפועל קרית שמונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        ksarticle: '',
      },
      {
        date: '25.02.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostessTeam: 'מ.ס. אשדוד',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        ksarticle: '',
      },
      {
        date: '25.02.2023',
        time: '---',
        score: '---',
        hostTeam: 'מכבי חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostessTeam: 'בית``ר ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        ksarticle: '',
      },
    ],
  },
  // 20220822152106
  // https://dreamteam.sport5.co.il/API/GamesCycle/GetGameCycle/25

  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 25,
    games: [
      {
        date: '04.03.2023',
        time: '---',
        score: '---',
        hostTeam: 'מכבי חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostessTeam: 'הפועל חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        ksarticle: '',
      },
      {
        date: '04.03.2023',
        time: '---',
        score: '---',
        hostTeam: 'בית``ר ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostessTeam: 'הפועל תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        ksarticle: '',
      },
      {
        date: '04.03.2023',
        time: '---',
        score: '---',
        hostTeam: 'מ.ס. אשדוד',
        hostTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostessTeam: 'בני סכנין',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        ksarticle: '',
      },
      {
        date: '04.03.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל קרית שמונה',
        hostTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostessTeam: 'מכבי נתניה',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        ksarticle: '',
      },
      {
        date: '04.03.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל חדרה',
        hostTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostessTeam: 'הפועל באר שבע',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        ksarticle: '',
      },
      {
        date: '04.03.2023',
        time: '---',
        score: '---',
        hostTeam: 'מכבי בני ריינה',
        hostTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostessTeam: 'סקציה נס ציונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        ksarticle: '',
      },
      {
        date: '04.03.2023',
        time: '---',
        score: '---',
        hostTeam: 'מכבי תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostessTeam: 'הפועל ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        ksarticle: '',
      },
    ],
  },
  // 20220822152115
  // https://dreamteam.sport5.co.il/API/GamesCycle/GetGameCycle/26

  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 26,
    games: [
      {
        date: '11.03.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostessTeam: 'מכבי תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        ksarticle: '',
      },
      {
        date: '11.03.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostessTeam: 'מכבי בני ריינה',
        hostessTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        ksarticle: '',
      },
      {
        date: '11.03.2023',
        time: '---',
        score: '---',
        hostTeam: 'סקציה נס ציונה',
        hostTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostessTeam: 'הפועל חדרה',
        hostessTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        ksarticle: '',
      },
      {
        date: '11.03.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל באר שבע',
        hostTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostessTeam: 'הפועל קרית שמונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        ksarticle: '',
      },
      {
        date: '11.03.2023',
        time: '---',
        score: '---',
        hostTeam: 'מכבי נתניה',
        hostTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostessTeam: 'מ.ס. אשדוד',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        ksarticle: '',
      },
      {
        date: '11.03.2023',
        time: '---',
        score: '---',
        hostTeam: 'בני סכנין',
        hostTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostessTeam: 'בית``ר ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        ksarticle: '',
      },
      {
        date: '11.03.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostessTeam: 'מכבי חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        ksarticle: '',
      },
    ],
  },
  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 27,
    games: [
      {
        date: '18.03.2023',
        hostTeam: 'הפועל באר שבע',
        hostTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        hostessTeam: 'הפועל ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        ksarticle: '',
        score: '---',
        time: '15:00',
      },
      {
        date: '18.03.2023',
        hostTeam: 'מכבי תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        hostessTeam: 'מכבי נתניה',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        ksarticle: '',
        score: '---',
        time: '17:30',
      },
      {
        date: '18.03.2023',
        hostTeam: 'הפועל חדרה',
        hostTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        hostessTeam: 'סקציה נס ציונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        ksarticle: '',
        score: '---',
        time: '18:30',
      },
      {
        date: '18.03.2023',
        hostTeam: 'בית``ר ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        hostessTeam: 'הפועל תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        ksarticle: '',
        score: '---',
        time: '19:30',
      },
      {
        date: '18.03.2023',
        hostTeam: 'מכבי חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        hostessTeam: 'מ.ס. אשדוד',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        ksarticle: '',
        score: '---',
        time: '20:00',
      },
      {
        date: '19.03.2023',
        hostTeam: 'בני סכנין',
        hostTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        hostessTeam: 'מכבי בני ריינה',
        hostessTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        ksarticle: '',
        score: '---',
        time: '19:00',
      },
      {
        date: '19.03.2023',
        hostTeam: 'הפועל חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        hostessTeam: 'הפועל קרית שמונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        ksarticle: '',
        score: '---',
        time: '20:15',
      },
    ],
  },
  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 28,
    games: [
      {
        date: '01.04.2023',
        time: '16:00',
        score: '---',
        hostTeam: 'הפועל תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostessTeam: 'סקציה נס ציונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        ksarticle: '',
      },
      {
        date: '01.04.2023',
        time: '18:30',
        score: '---',
        hostTeam: 'הפועל ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostessTeam: 'מכבי תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        ksarticle: '',
      },
      {
        date: '01.04.2023',
        time: '20:00',
        score: '---',
        hostTeam: 'מכבי חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostessTeam: 'הפועל באר שבע',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        ksarticle: '',
      },
      {
        date: '01.04.2023',
        time: '20:00',
        score: '---',
        hostTeam: 'מ.ס. אשדוד',
        hostTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostessTeam: 'מכבי נתניה',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        ksarticle: '',
      },
      {
        date: '02.04.2023',
        time: '20:30',
        score: '---',
        hostTeam: 'מכבי בני ריינה',
        hostTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostessTeam: 'הפועל חדרה',
        hostessTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        ksarticle: '',
      },
      {
        date: '02.04.2023',
        time: '20:30',
        score: '---',
        hostTeam: 'הפועל קרית שמונה',
        hostTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostessTeam: 'בני סכנין',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        ksarticle: '',
      },
      {
        date: '03.04.2023',
        time: '20:15',
        score: '---',
        hostTeam: 'בית``ר ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostessTeam: 'הפועל חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        ksarticle: '',
      },
    ],
  },
  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 29,
    games: [
      {
        date: '04.04.2023',
        time: '19:45',
        score: '---',
        hostTeam: 'מכבי נתניה',
        hostTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostessTeam: 'הפועל ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        ksarticle: '',
      },
      {
        date: '04.04.2023',
        time: '20:00',
        score: '---',
        hostTeam: 'הפועל באר שבע',
        hostTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostessTeam: 'מ.ס. אשדוד',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        ksarticle: '',
      },
      {
        date: '04.04.2023',
        time: '20:30',
        score: '---',
        hostTeam: 'מכבי תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostessTeam: 'מכבי חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        ksarticle: '',
      },
    ],
  },
  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 30,
    games: [
      {
        date: '08.04.2023',
        time: '17:30',
        score: '---',
        hostTeam: 'מ.ס. אשדוד',
        hostTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostessTeam: 'הפועל ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        ksarticle: '',
      },
      {
        date: '08.04.2023',
        time: '20:00',
        score: '---',
        hostTeam: 'מכבי חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostessTeam: 'מכבי נתניה',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        ksarticle: '',
      },
      {
        date: '10.04.2023',
        time: '20:30',
        score: '---',
        hostTeam: 'הפועל באר שבע',
        hostTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostessTeam: 'מכבי תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        ksarticle: '',
      },
    ],
  },
  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 31,
    games: [
      {
        date: '15.04.2023',
        time: '16:00',
        score: '---',
        hostTeam: 'הפועל חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostessTeam: 'הפועל תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        ksarticle: '',
      },
      {
        date: '15.04.2023',
        time: '18:30',
        score: '---',
        hostTeam: 'הפועל ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostessTeam: 'מכבי חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        ksarticle: '',
      },
      {
        date: '15.04.2023',
        time: '20:00',
        score: '---',
        hostTeam: 'הפועל חדרה',
        hostTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostessTeam: 'הפועל קרית שמונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        ksarticle: '',
      },
      {
        date: '15.04.2023',
        time: '20:00',
        score: '---',
        hostTeam: 'סקציה נס ציונה',
        hostTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostessTeam: 'מכבי בני ריינה',
        hostessTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        ksarticle: '',
      },
      {
        date: '15.04.2023',
        time: '20:00',
        score: '---',
        hostTeam: 'מכבי תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostessTeam: 'מ.ס. אשדוד',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        ksarticle: '',
      },
      {
        date: '16.04.2023',
        time: '20:15',
        score: '---',
        hostTeam: 'בני סכנין',
        hostTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostessTeam: 'בית``ר ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        ksarticle: '',
      },
      {
        date: '16.04.2023',
        time: '20:30',
        score: '---',
        hostTeam: 'מכבי נתניה',
        hostTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostessTeam: 'הפועל באר שבע',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        ksarticle: '',
      },
    ],
  },
  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 32,
    games: [
      {
        date: '22.04.2023',
        time: '16:00',
        score: '---',
        hostTeam: 'הפועל תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostessTeam: 'מכבי בני ריינה',
        hostessTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        ksarticle: '',
      },
      {
        date: '22.04.2023',
        time: '18:30',
        score: '---',
        hostTeam: 'הפועל ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostessTeam: 'הפועל באר שבע',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        ksarticle: '',
      },
      {
        date: '22.04.2023',
        time: '20:00',
        score: '---',
        hostTeam: 'מכבי נתניה',
        hostTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostessTeam: 'מכבי תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        ksarticle: '',
      },
      {
        date: '22.04.2023',
        time: '20:00',
        score: '---',
        hostTeam: 'הפועל קרית שמונה',
        hostTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostessTeam: 'סקציה נס ציונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        ksarticle: '',
      },
      {
        date: '22.04.2023',
        time: '20:00',
        score: '---',
        hostTeam: 'הפועל חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostessTeam: 'בני סכנין',
        hostessTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        ksarticle: '',
      },
      {
        date: '23.04.2023',
        time: '20:15',
        score: '---',
        hostTeam: 'בית``ר ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostessTeam: 'הפועל חדרה',
        hostessTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        ksarticle: '',
      },
      {
        date: '23.04.2023',
        time: '20:30',
        score: '---',
        hostTeam: 'מ.ס. אשדוד',
        hostTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostessTeam: 'מכבי חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        ksarticle: '',
      },
    ],
  },
  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 33,
    games: [
      {
        date: '29.04.2023',
        time: '---',
        score: '---',
        hostTeam: 'מכבי נתניה',
        hostTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostessTeam: 'מ.ס. אשדוד',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        ksarticle: '',
      },
      {
        date: '29.04.2023',
        time: '---',
        score: '---',
        hostTeam: 'מכבי תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostessTeam: 'הפועל ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        ksarticle: '',
      },
      {
        date: '29.04.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל באר שבע',
        hostTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostessTeam: 'מכבי חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        ksarticle: '',
      },
      {
        date: '29.04.2023',
        time: '17:00',
        score: '---',
        hostTeam: 'מכבי בני ריינה',
        hostTeamImage: 'https://www.sport5.co.il/files/8/154068.png',
        hostessTeam: 'הפועל קרית שמונה',
        hostessTeamImage: 'https://www.sport5.co.il/files/2/131442.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1641',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=845',
        ksarticle: '',
      },
      {
        date: '29.04.2023',
        time: '20:00',
        score: '---',
        hostTeam: 'סקציה נס ציונה',
        hostTeamImage: 'https://www.sport5.co.il/files/5/119025.png',
        hostessTeam: 'בית``ר ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/3/131383.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1637',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=191',
        ksarticle: '',
      },
      {
        date: '30.04.2023',
        time: '19:00',
        score: '---',
        hostTeam: 'הפועל חדרה',
        hostTeamImage: 'https://www.sport5.co.il/files/7/131437.png',
        hostessTeam: 'הפועל חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131441.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=8518',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1632',
        ksarticle: '',
      },
      {
        date: '30.04.2023',
        time: '20:15',
        score: '---',
        hostTeam: 'בני סכנין',
        hostTeamImage: 'https://www.sport5.co.il/files/5/131435.png',
        hostessTeam: 'הפועל תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/131444.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=195',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=164',
        ksarticle: '',
      },
    ],
  },
  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 34,
    games: [
      {
        date: '06.05.2023',
        time: '---',
        score: '---',
        hostTeam: 'מ.ס. אשדוד',
        hostTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostessTeam: 'הפועל באר שבע',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        ksarticle: '',
      },
      {
        date: '06.05.2023',
        time: '---',
        score: '---',
        hostTeam: 'מכבי חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostessTeam: 'מכבי תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        ksarticle: '',
      },
      {
        date: '06.05.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostessTeam: 'מכבי נתניה',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        ksarticle: '',
      },
    ],
  },
  {
    isFirstCycle: false,
    isLastCycle: false,
    cycleNum: 35,
    games: [
      {
        date: '13.05.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל ירושלים',
        hostTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostessTeam: 'מ.ס. אשדוד',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        ksarticle: '',
      },
      {
        date: '13.05.2023',
        time: '---',
        score: '---',
        hostTeam: 'מכבי נתניה',
        hostTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostessTeam: 'מכבי חיפה',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        ksarticle: '',
      },
      {
        date: '13.05.2023',
        time: '---',
        score: '---',
        hostTeam: 'מכבי תל אביב',
        hostTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostessTeam: 'הפועל באר שבע',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        ksarticle: '',
      },
    ],
  },
  {
    isFirstCycle: false,
    isLastCycle: true,
    cycleNum: 36,
    games: [
      {
        date: '20.05.2023',
        time: '---',
        score: '---',
        hostTeam: 'מ.ס. אשדוד',
        hostTeamImage: 'https://www.sport5.co.il/files/0/131440.png',
        hostessTeam: 'מכבי תל אביב',
        hostessTeamImage: 'https://www.sport5.co.il/files/0/143450.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=198',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=192',
        ksarticle: '',
      },
      {
        date: '20.05.2023',
        time: '---',
        score: '---',
        hostTeam: 'הפועל באר שבע',
        hostTeamImage: 'https://www.sport5.co.il/files/0/154570.png',
        hostessTeam: 'מכבי נתניה',
        hostessTeamImage: 'https://www.sport5.co.il/files/4/143204.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=1639',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=193',
        ksarticle: '',
      },
      {
        date: '20.05.2023',
        time: '---',
        score: '---',
        hostTeam: 'מכבי חיפה',
        hostTeamImage: 'https://www.sport5.co.il/files/1/131391.png',
        hostessTeam: 'הפועל ירושלים',
        hostessTeamImage: 'https://www.sport5.co.il/files/1/142591.png',
        hostTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=163',
        hostessTeamLink: 'https://www.sport5.co.il/team.aspx?FolderID=9749',
        ksarticle: '',
      },
    ],
  },
];
