export default [
  {
    teamName: 'מכבי חיפה',
    teamAbr: 'MHA',
    val: 1449,
    teamStrength: 5,
    teamImg: 'https://i.ibb.co/4mRvkH7/1449.png',
    colors: ['#276b43', 'white'],
  },
  {
    teamName: 'הפועל באר שבע',
    teamAbr: 'HBS',
    val: 36,
    teamStrength: 5,
    teamImg: 'https://i.ibb.co/HzWNSvL/36.png',
    colors: ['#da2333', 'white'],
  },
  {
    teamName: 'מכבי תל אביב',
    teamAbr: 'MTA',
    val: 41,
    teamStrength: 4,

    teamImg: 'https://i.ibb.co/Rcv5J8Y/41.png',
    colors: ['#20458d', '#dfc91b'],
  },
  {
    teamName: 'מכבי נתניה',
    teamAbr: 'MNT',
    val: 39,
    teamStrength: 4,
    teamImg: 'https://i.ibb.co/9Zp9mDj/39.png',
    colors: ['#CCCC00', 'black'],
  },
  {
    teamName: 'הפועל תל אביב',
    teamAbr: 'HTA',
    val: 25,
    teamStrength: 3,
    teamImg: 'https://i.ibb.co/7RjjQ9Y/25.png',
    colors: ['#da2333', 'white'],
  },
  {
    teamName: 'בני סכנין',
    teamAbr: 'BNS',
    val: 26,
    teamStrength: 3,
    teamImg: 'https://i.ibb.co/9HwGzyF/26.png',
    colors: ['#da2333', 'white'],
  },
  {
    teamName: 'הפועל קרית שמונה',
    teamAbr: 'IKS',
    val: 28,
    teamStrength: 2,
    teamImg: 'https://i.ibb.co/SBv9Pb9/28.png',
    colors: ['#04053b', 'white'],
  },
  {
    teamName: 'הפועל חדרה',
    teamAbr: 'HAD',
    val: 1349,
    teamStrength: 2,
    teamImg: 'https://i.ibb.co/D9wsy6p/1349.png',
    colors: ['#da2333', 'white'],
  },
  {
    teamName: 'מ.ס. אשדוד',
    teamAbr: 'FCA',
    val: 46,
    teamStrength: 3,
    teamImg: 'https://i.ibb.co/B23JcFJ/46.png',
    colors: ['#da2333', '#fdee01'],
  },
  {
    teamName: 'בית``ר ירושלים',
    teamAbr: 'BEI',
    val: 40,
    teamStrength: 3,
    teamImg: 'https://i.ibb.co/n066Z7Q/40.png',
    colors: ['#CCCC00', 'black'],
  },
  {
    teamName: 'הפועל חיפה',
    teamAbr: 'HHA',
    val: 38,
    teamStrength: 3,
    teamImg: 'https://i.ibb.co/8DQg7T8/38.png',
    colors: ['#da2333', 'black'],
  },
  {
    teamName: 'הפועל ירושלים',
    teamAbr: 'HJE',
    val: 1271,
    teamStrength: 3,
    teamImg: 'https://i.ibb.co/KFdXdCs/1271.png',
    colors: ['#da2333', 'black'],
  },
  {
    teamName: 'מכבי בני ריינה',
    teamAbr: 'RNA',
    val: 3740,
    teamStrength: 1,
    teamImg: 'https://i.ibb.co/cwg4Fjd/3740.png',
    colors: ['#2e74a5', 'white'],
  },
  {
    teamName: 'סקציה נס ציונה',
    teamAbr: 'SNT',
    val: 448,
    teamStrength: 1,
    teamImg: 'https://i.ibb.co/LNXfH4W/448.png',
    colors: ['#e5911b', 'white'],
  },
];
